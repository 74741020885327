import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { MARKET_NAMES } from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup';
import { ExpandButton } from 'src/domains/sportsbook/webview/components/expandButton/ExpandButton';
import { GoalScorerType, GoalScorerMarketState, ToggleExpandList } from './GoalScorerMarket.state';
import { GoalscorerName } from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup.style';
import {
    GoalScorerMarketWrapper,
    GoalScorerMarketListWrapper,
    GoalScorerMarketListItem,
    MarketGroupCaptionDesktop,
    CaptionGroupDesktop,
    CaptionDesktop,
} from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalScorerMarket.style';

interface GoalScorerMarketListItemWrapperPropsType {
    goalScorer: GoalScorerType;
}

export const GoalScorerMarketListItemWrapper = observer(
    'GoalScorerMarketListItemWrapper',
    (props: GoalScorerMarketListItemWrapperPropsType): JSX.Element | null => {
        const { name, first, anytime, hatTrick } = props.goalScorer;

        return (
            <GoalScorerMarketListItem key={name}>
                <GoalscorerName templateId=''>
                    {name === 'No goalscorer' ? (
                        <I18n
                            langKey='selection.football.match.no-goalscorer'
                            defaultText='No goalscorer'
                        />
                    ) : (
                        name
                    )}
                </GoalscorerName>
                <Selection
                    borderBottom={true}
                    borderLeft={true}
                    borderRight={true}
                    selectionId={first ?? undefined}
                />
                <Selection
                    borderBottom={true}
                    borderLeft={true}
                    borderRight={true}
                    selectionId={anytime ?? undefined}
                />
                <Selection
                    borderBottom={true}
                    borderLeft={true}
                    selectionId={hatTrick ?? undefined}
                />
            </GoalScorerMarketListItem>
        );
    }
);

interface GoalScorerMarketListPropsType {
    goalScorerList: Array<GoalScorerType>;
    isExpanded: boolean;
}

const GoalScorerMarketList = observer(
    'GoalScorerMarketList',
    (props: GoalScorerMarketListPropsType): JSX.Element | null => {
        const { goalScorerList, isExpanded } = props;

        return (
            <GoalScorerMarketListWrapper>
                {goalScorerList.length > 0 ? (
                    <MarketGroupCaptionDesktop>
                        <CaptionDesktop isFirst={true}>
                            <I18n
                                langKey='events.goalscorer-market.player'
                                defaultText='Player'
                            />
                        </CaptionDesktop>

                        <CaptionGroupDesktop>
                            {Object.keys(MARKET_NAMES).map((templateId: string) => {
                                const marketName = MARKET_NAMES[templateId];
                                return <CaptionDesktop key={templateId}>{marketName}</CaptionDesktop>;
                            })}
                        </CaptionGroupDesktop>
                    </MarketGroupCaptionDesktop>
                ) : null}

                {goalScorerList.slice(0, isExpanded === true ? goalScorerList.length : 6).map((elem) => {
                    return (
                        <GoalScorerMarketListItemWrapper
                            key={elem.name}
                            goalScorer={elem}
                        />
                    );
                })}
            </GoalScorerMarketListWrapper>
        );
    }
);

export interface GoalScorerMarketPropsType {
    state: GoalScorerMarketState;
}

export const GoalScorerMarket = observer('GoalScorerMarket', ({ state }: GoalScorerMarketPropsType) => {
    const [toggleState] = useState(() => new ToggleExpandList());

    return (
        <>
            <GoalScorerMarketWrapper>
                <GoalScorerMarketList
                    goalScorerList={state.goalScorerListHome}
                    isExpanded={toggleState.isExpanded}
                />
                <GoalScorerMarketList
                    goalScorerList={state.goalScorerListAway}
                    isExpanded={toggleState.isExpanded}
                />
            </GoalScorerMarketWrapper>

            {state.noGoalScore !== null && (toggleState.isExpanded || state.isPossibleToExpandList === false) ? (
                <GoalScorerMarketListItemWrapper goalScorer={state.noGoalScore} />
            ) : null}

            {state.isPossibleToExpandList ? (
                <ExpandButton
                    isExpanded={toggleState.isExpanded}
                    toggleExpand={toggleState.toggleExpand}
                />
            ) : null}
        </>
    );
});

import { action, computed, makeObservable, observable } from 'mobx';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { Amount } from 'src_common/common/amount/Amount';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { generateRandomAlphanumeric } from 'src/domains/players/webview//components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/generateRandomAlphanumeric';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { apiDepositInitiateRequest } from 'src/api_openapi/v2/yaspa/apiDepositInitiate';
import { YaspaBankingApiPayInRequestType } from 'src/api_openapi/v2/yaspa/schemas';

export interface DepositYaspaPageType {
    isSignup: boolean;
    getAmountInput: () => FormInputState<string, Amount>;
    handleRedirectToYaspaFrame: (url: string) => void;
}

export class YaspaState {
    @observable public isSubmiting: boolean = false;
    @observable public error: string | null = null;

    public constructor(
        private readonly common: Common,
        private depositYaspaPage: DepositYaspaPageType
    ) {
        makeObservable(this);
    }

    @computed public get customerData(): YaspaBankingApiPayInRequestType | null {
        const customer = BasicDataModel.get(this.common).basicDataReady;

        if (customer === null) {
            return null;
        }
        const amountInput = this.depositYaspaPage.getAmountInput();

        if (amountInput.result.value.type === 'error') {
            return null;
        }

        const randomReferenceNumber = generateRandomAlphanumeric(15);

        return {
            customerIdentifier: customer.id.toString(),
            paymentGiro: customer.currency === 'GBP' ? 'FPS' : 'SEPA',
            amount: amountInput.result.value.data.toFixed(2),
            reference: randomReferenceNumber,
            journeyType: 'HOSTED_PAYMENT',
            currency: customer.currency,
            successBankRedirectUrl: `${this.common.websiteBaseUrl}${this.common.startingUrl}`,
            successRedirectUrl: `${this.common.websiteBaseUrl}${this.common.startingUrl}`,
            failureBankRedirectUrl: `${this.common.websiteBaseUrl}${this.common.startingUrl}`,
            failureRedirectUrl: `${this.common.websiteBaseUrl}${this.common.startingUrl}`,
            searchableText: `${this.common.envVariables.universe}-${customer.id}`,
        };
    }

    @computed public get isButtonDisabled(): boolean {
        if (this.customerData === null) {
            return true;
        }

        if (this.isSubmiting) {
            return true;
        }

        return false;
    }

    @action public submitDepositForm = async (): Promise<void> => {
        try {
            this.isSubmiting = true;
            this.error = null;

            if (this.customerData === null) {
                this.createErrorMessage('Unknown customer data');
                return;
            }
            const oldFormatAmount = ConfigComponents.get(this.common).precision.valueOldFormat(
                new Amount(this.customerData.amount)
            );

            const googleTagManager = GoogleTagManagerState.get(this.common);

            if (this.depositYaspaPage.isSignup) {
                googleTagManager.depositedMoney(oldFormatAmount, true);
                googleTagManager.gtmSignUpStepFour(oldFormatAmount);
            } else {
                googleTagManager.depositedMoney(oldFormatAmount, false);
            }

            const { gatewayApiHost } = this.common.envVariables;

            if (gatewayApiHost === null) {
                this.createErrorMessage('Unknown gatewayApiHost');
                return;
            }

            const response = await apiDepositInitiateRequest(
                gatewayApiHost,
                { requestBody: this.customerData },
                {
                    'x-client-request-id': `${this.common.envVariables.universe}-${this.customerData.customerIdentifier}`,
                }
            );

            if (response.status !== 200) {
                this.createErrorMessage(response.body.errors?.[0]?.message);
                return;
            }

            this.depositYaspaPage.handleRedirectToYaspaFrame(response.body.data.link);
        } catch (e) {
            this.createErrorMessage();
        } finally {
            this.isSubmiting = false;
        }
    };

    @action public createErrorMessage = (message?: string | undefined): void => {
        this.error = message ?? 'Unknown Error';
    };
}

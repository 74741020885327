import styled from '@emotion/styled';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const YaspaIFrameStyled = styled(Iframe, { label: 'YaspaIFrameStyled' })`
    width: 100%;
    border: none;
    height: 100%;
`;

export const YaspaContainer = styled('div', { label: 'YaspaContainer' })`
    margin-top: 16px;
`;

export const YaspaDepositButton = styled(Button, { label: 'YaspaDepositButton' })`
    width: 100%;
`;

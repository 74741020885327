import { TrpcClient } from 'src/appState/TrpcClient';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { computed } from 'mobx';
import { DateTime } from 'src_common/utils/time/time';
import { BlockingNotificationType } from 'src_server/trpc/types/accountModel/accountResponse';

export class BlockingNotificationsState {
    public constructor(
        private readonly trpcClient: TrpcClient,
        private readonly basicDataComp: BasicDataModel
    ) {}

    @computed public get getBlockingNotificationType(): BlockingNotificationType | null {
        const customerData = this.basicDataComp.basicDataReady;

        if (customerData === null) {
            return null;
        }

        const allNotifications = customerData.prompts?.notificationList ?? [];
        const activeNotifications = [...allNotifications].filter((notification) => notification.active);

        return (
            activeNotifications.find((notification) => {
                switch (notification.name) {
                    case 'changeOfLicense':
                        return BlockingNotificationType.changeOfLicense;
                    case 'kycDepositRestriction':
                        if (
                            customerData.depositRestriction?.source === 'kycThreshold' &&
                            this.checkIsDepositRestrictionActive()
                        ) {
                            return BlockingNotificationType.kycDepositRestriction;
                        }

                        return null;
                }
            })?.name ?? null
        );
    }

    private checkIsDepositRestrictionActive = (): boolean => {
        const depositRestriction = this.basicDataComp.basicDataReady?.depositRestriction ?? null;

        if (depositRestriction === null) {
            return false;
        }

        if (depositRestriction.permanent === true) {
            return true;
        }

        if (DateTime.current().isBefore(depositRestriction.expiryDate ?? '')) {
            return true;
        }

        return false;
    };

    public handleConfirmNewLicenceNotification = async (): Promise<void> => {
        await this.onConfirm(BlockingNotificationType.changeOfLicense);
    };

    public handleConfirmKycDepositRestrictionNotification = async (): Promise<void> => {
        await this.onConfirm(BlockingNotificationType.kycDepositRestriction);
    };

    private onConfirm = async (blockingNotificationType: BlockingNotificationType): Promise<void> => {
        const confirmedNotification = { name: blockingNotificationType, active: false };

        const allNotifications = this.basicDataComp.basicDataReady?.prompts?.notificationList ?? [];
        const updatedNnotifications = allNotifications.map((notification) =>
            notification.name === blockingNotificationType ? confirmedNotification : notification
        );

        await this.trpcClient.client.accounts.approveNotification.mutate({
            prompts: { notificationList: updatedNnotifications },
        });

        this.basicDataComp.basicData.refresh();
    };
}

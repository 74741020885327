import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    RealexErrorType,
    RealexTabState,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab.state';
import { KycWarning } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/kycWarning/KycWarning';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import { RingFencedFundsMessage } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/ringFencedFundsWrapper/RingFencedFundsWrapper';
import {
    PayButton,
    ErrorLink,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexTab.style';
import {
    CardInfo,
    CashBalance,
    CashBalanceWrapper,
    DepositTabWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/DepositProcedure.style';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { RemainingLimit } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit';
import { YaspaDeposit } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/yaspa/YaspaDeposit';
interface PropsType {
    state: RealexTabState;
}

interface ErrorMessageType {
    message: string;
    languagesState: LanguagesState;
}

const FailedDepositRestrictionErrorMessage = observer(
    'failedDepositRestriction',
    ({ languagesState, message }: ErrorMessageType) => (
        <div>
            {languagesState.translateTokens(message, () => {
                return (
                    <ErrorLink
                        to={{ account: 'limits' }}
                        dataTest='error'
                    >
                        responsible gambling tools
                    </ErrorLink>
                );
            })}
        </div>
    )
);

const LimitReachedErrorMessage = observer(
    'LimitReachedErrorMessage',
    ({ languagesState, message }: ErrorMessageType) => (
        <div>
            {languagesState.translateTokens(message, () => {
                return (
                    <ErrorLink
                        to={{ account: 'limits' }}
                        dataTest='error'
                    >
                        <I18n
                            langKey='account.top-up.errors.deposit-limit-reached.link'
                            defaultText='here'
                        />
                    </ErrorLink>
                );
            })}
        </div>
    )
);

export const mapRealexErrorsMessages = (error: RealexErrorType, languagesState: LanguagesState): React.ReactNode => {
    if (error.type === 'failed-deposit-restriction') {
        return (
            <Messages
                type='error'
                marginTop='8px'
                key={error.type}
                message={
                    <FailedDepositRestrictionErrorMessage
                        message={error.message}
                        languagesState={languagesState}
                    />
                }
            />
        );
    }

    if (error.type === 'limit-reached') {
        return (
            <Messages
                type='error'
                marginTop='8px'
                key={error.type}
                message={
                    <LimitReachedErrorMessage
                        message={error.message}
                        languagesState={languagesState}
                    />
                }
            />
        );
    }

    return (
        <Messages
            type='error'
            marginTop='8px'
            key={error.type}
            message={error.message}
        />
    );
};

export const RealexTab = observer('RealexTab', ({ state }: PropsType): JSX.Element => {
    const {
        config: { showKycStatus, rollingNetDepositLimit, useOpenBanking },
        common,
    } = useAppStateContext();
    const languagesState = LanguagesState.get(common);

    return (
        <DepositTabWrapper>
            <CashBalanceWrapper>
                <I18n
                    langKey='account.top-up.cash-balance-label'
                    defaultText='Cash balance'
                />
                <CashBalance>{state.showBalance}</CashBalance>
            </CashBalanceWrapper>

            <Input
                type='text'
                maxLength={10}
                placeholder='0.00'
                currency={BasicDataModel.get(common).moneySymbol}
                label={
                    <I18n
                        langKey='account.top-up.description-label'
                        defaultText='Deposit amount'
                    />
                }
                state={state.depositAmount}
                onChange={state.onChange}
                onBlur={state.setAmount}
                colorTheme='light'
                inputHasBackground={false}
                dataTest='deposit-amount-input'
            />

            <PaymentAdditions
                key='payment-additions'
                currency={BasicDataModel.get(common).moneySymbol}
                value={state.depositAmount.value}
                onChange={state.handleAdditionsChange}
            />

            {rollingNetDepositLimit === false || state.remainingLimitState.getRemainingLimit === undefined ? null : (
                <RemainingLimit
                    languagesState={languagesState}
                    value={state.remainingLimitState.getRemainingLimit.format(BasicDataModel.get(common).currency)}
                />
            )}

            {useOpenBanking?.type === 'yaspa' ? <YaspaDeposit depositYaspaPage={state.depositYaspaPage} /> : null}

            {state.errors.length > 0
                ? state.errors.map((error) => mapRealexErrorsMessages(error, languagesState))
                : null}

            {state.hasRingFencedFunds === false ? (
                <RingFencedFundsMessage
                    isMoreShown={state.showMoreRingFenceFlag}
                    toggleShowMore={state.toggleShowMore}
                />
            ) : null}
            <PayButton
                size='large'
                disabled={state.isButtonDisabled || state.areMissingFieldsForBillingInfoRealex()}
                onClick={state.submitDepositForm}
                type='submit'
                dataTest='deposit-pay-debit-card'
            >
                <I18n
                    langKey='account.top-up.pay-card-button'
                    defaultText='Pay using debit card'
                />
            </PayButton>

            <CardInfo data-test='deposit-card-info'>
                <I18n
                    langKey='account.top-up.top-upcard-info'
                    defaultText='Credit cards are no longer accepted'
                />
            </CardInfo>
            {showKycStatus === true ? <KycWarning isSignup={false} /> : null}
        </DepositTabWrapper>
    );
});

import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';
import { DragonBetDarkLogo } from 'src/domains/layouts/shared/assets/icons/logo/dragonbetDark.logo';

export const DragonBetLogo = styled(DragonBetDarkLogo, { label: 'DragonBetLogo' })`
    height: 36px;
    width: 171px;
    margin: 10px;
`;

export const Overlay = withConfig(theme => styled('div', { label: 'Overlay' })`
    align-items: center;
    background: ${theme.star.overlay.bgColorSecondary};
    color: ${theme.star.blockingNotifications.textColor};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const Content = withConfig(theme => styled('div', { label: 'Content' })`
    max-width: 400px;
    background-color: ${theme.star.blockingNotifications.bgColor};
    padding: 24px;
    position: relative;
    min-width: 288px;
`);

export const PopupHeader = withConfig(theme => styled('div', { label: 'PopupHeader' })`
    margin: 0 0 16px 0;
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.xMedium};
    line-height: 1.4;
    text-align: center;
`);

export const PopupDescription = withConfig(theme => styled('p', { label: 'PopupDescription' })`
    margin: 0 0 16px 0;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.25;
`);

export const AcceptBtn = styled(Button, { label: 'AcceptBtn' })`
    width: 100%;
`;

import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { Content, Overlay, PopupHeader, PopupDescription, AcceptBtn } from './BlockingNotificationsDefault.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface BlockingKycNotificationType {
    onConfirm: () => Promise<void>;
}

export const BlockingKycDepositRestrictionNotification = observer(
    'BlockingKycDepositRestrictionNotification',
    ({ onConfirm }: BlockingKycNotificationType): JSX.Element | null => {
        return (
            <Overlay data-test='overlay-prompt'>
                <Content>
                    <PopupHeader data-test='popup-header'>
                        <I18n
                            langKey='account.change-kyc-notification.title'
                            defaultText='Action Needed: Verify Your Account'
                        />
                    </PopupHeader>
                    <PopupDescription data-test='popup-description'>
                        <I18n
                            langKey='account.change-kyc-notification.description-part-1'
                            defaultText='Your deposits are temporarily restricted, but you can still place bets. Please check your email for instructions on submitting the necessary documents. Once verified, deposit restrictions will be lifted!'
                        />
                    </PopupDescription>
                    <AcceptBtn
                        onClick={onConfirm}
                        size='medium'
                        dataTest='popup-button'
                    >
                        <I18n
                            langKey='account.change-kyc-notification.confirm-button.label'
                            defaultText='Accept'
                        />
                    </AcceptBtn>
                </Content>
            </Overlay>
        );
    }
);

import React from 'react';
import { MarketHeader } from 'src/domains/sportsbook/webview/components/marketHeader/MarketHeader';
import { ExpandButton } from 'src/domains/sportsbook/webview/components/expandButton/ExpandButton';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import {
    GoalScorerMarket,
    GoalScorerMarketListItemWrapper,
} from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalScorerMarket';
import { observer } from 'src/utils/mobx-react';
import { FiltersNewView } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { GoalscorerMarketGroupState } from './GoalscorerMarketGroup.state';
import {
    Caption,
    CaptionGroup,
    MarketGroupCaption,
    Goalscorers,
    MarketGroupFilters,
    SelectionsGroup,
    SelectionsGroupWrap,
} from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalscorerMarketGroup.style';
import {
    MarketGroupWrapper,
    MarketSeparator,
} from 'src/domains/sportsbook/webview/components/eventMarkets/marketGroup/MarketGroup.style';
import {
    GoalScorerMarketState,
    GoalScorerType,
} from 'src/domains/sportsbook/webview/components/goalscorerMarketGroup/GoalScorerMarket.state';

interface PropsType {
    eventId: EventId;
    markets: Array<MarketId>;
    selections: Record<string, Array<SelectionModel>>;
    isOpen: boolean;
    onClick(e: React.MouseEvent): void;
}
export const MARKET_NAMES: Record<string, JSX.Element> = {
    'first-goalscorer': (
        <I18n
            langKey='events.goalscorer-market.first'
            defaultText='First'
        />
    ),
    'anytime-goalscorer': (
        <I18n
            langKey='events.goalscorer-market.anytime'
            defaultText='Anytime'
        />
    ),
    'hattrick-goalscorer': (
        <I18n
            langKey='events.goalscorer-market.hattrick'
            defaultText='Hat-trick'
        />
    ),
};

function getTemplateIds(markets: Array<MarketId>): Array<string | undefined> {
    return markets.map((id) => id.getModel()?.newTemplateId);
}

export const GoalscorerMarketGroup = observer('GoalscorerMarketGroup', (props: PropsType) => {
    const { appLayoutsState } = useAppStateContext();
    const { breakpointsState } = appLayoutsState;

    const { isOpen, onClick, selections } = props;

    const [state] = React.useState(() => new GoalscorerMarketGroupState());
    const goalScorerState = new GoalScorerMarketState(selections);

    const { open, isExpanded, toggleExpand, handleOpenHome, handleOpenAway } = state;
    const getGoalscorers = (openOption: 'H' | 'A'): GoalScorerType[] => {
        const noGoalScore = goalScorerState.noGoalScore === null ? [] : [goalScorerState.noGoalScore];

        switch (openOption) {
            case 'H':
                return goalScorerState.goalScorerListHome.concat(noGoalScore);
            case 'A':
                return goalScorerState.goalScorerListAway.concat(noGoalScore);
        }
    };

    const event = props.eventId.getEventModel();

    if (event === null) {
        return null;
    }

    const participants = event.participants;

    const market = event.markets[0] ?? null;
    const marketId = market === null ? undefined : market.id2;

    if (marketId === undefined) {
        return null;
    }

    const homeName: string | null = Object.values(participants).find((x) => x.role === 'home')?.name ?? null;
    const awayName: string | null = Object.values(participants).find((x) => x.role === 'away')?.name ?? null;

    const goalscorers = getGoalscorers(open);
    // Show more/less button only if there
    // are more then 6 participants
    const expandButton =
        goalscorers.length > 6 ? (
            <ExpandButton
                isExpanded={isExpanded}
                toggleExpand={toggleExpand}
            />
        ) : null;

    const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;

    const filters = [
        {
            key: 'H',
            label:
                homeName === null ? (
                    <I18n
                        langKey='events.selections-group.team.home'
                        defaultText='Home'
                    />
                ) : (
                    homeName
                ),
            onClick: handleOpenHome,
        },
        {
            key: 'A',
            label:
                awayName === null ? (
                    <I18n
                        langKey='events.selections-group.team.away'
                        defaultText='Away'
                    />
                ) : (
                    awayName
                ),
            onClick: handleOpenAway,
        },
    ];

    return (
        <>
            <MarketGroupWrapper className='market-group--goalscorer'>
                <MarketHeader
                    name='First Goalscorer'
                    templateId='first-goalscorer'
                    isOpen={isOpen}
                    onClick={(e: React.MouseEvent): void => onClick(e)}
                    eventId={event.id2}
                    marketId={marketId}
                />
                {isOpen ? (
                    <SelectionsGroupWrap>
                        {isLargeDesktop === true ? (
                            <GoalScorerMarket state={goalScorerState} />
                        ) : (
                            <>
                                <MarketGroupFilters className='market-group__teams'>
                                    <FiltersNewView
                                        select={open}
                                        skin={FiltersSkin.SECONDARY}
                                        filters={filters}
                                    />
                                </MarketGroupFilters>

                                <MarketGroupCaption>
                                    <Caption isFirst={true}>
                                        <I18n
                                            langKey='events.goalscorer-market.player'
                                            defaultText='Player'
                                        />
                                    </Caption>
                                    <CaptionGroup>
                                        {Object.keys(MARKET_NAMES)
                                            .filter((id) => getTemplateIds(props.markets).includes(id))
                                            .map((templateId: string) => {
                                                const marketName = MARKET_NAMES[templateId];
                                                return <Caption key={templateId}>{marketName}</Caption>;
                                            })}
                                    </CaptionGroup>
                                </MarketGroupCaption>

                                <SelectionsGroup>
                                    <Goalscorers className='market-group__goalscorers'>
                                        {goalscorers.slice(0, isExpanded ? goalscorers.length : 9).map((value, idx) => {
                                            return (
                                                <GoalScorerMarketListItemWrapper
                                                    key={`${idx}-${value.name}`}
                                                    goalScorer={value}
                                                />
                                            );
                                        })}
                                    </Goalscorers>
                                </SelectionsGroup>
                            </>
                        )}
                        {isLargeDesktop === true ? null : expandButton}
                    </SelectionsGroupWrap>
                ) : null}
            </MarketGroupWrapper>

            {isOpen ? null : <MarketSeparator />}
        </>
    );
});

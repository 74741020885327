import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { MarketingQuestionsState } from 'src/domains/players/state/MarketingQuestionsState';
import { MarketingNotificationsState } from 'src/domains/players/state/MarketingNotificationsState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { ConfirmationBoxState } from 'src/domains/players/state/ConfirmationBoxState';
import { MessageBoxState } from 'src/domains/players/state/MessageBoxState';
import { SignupPopupState } from 'src/domains/players/state/SignupPopupState';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { StarLoginState } from 'src/domains/players/state/starLoginState/StarLoginState';
import { SignUpState, SignupType } from 'src/domains/players/webview/components/SignUp';
import { SpecialEvents } from 'src/domains/players/state/specialEventsState/SpecialEvents';
import { LuckyKingWalletState } from 'src/domains/players/state/LuckyKingWalletState';
import { StreamViewDetails } from 'src/domains/sportsbook/shared/Types';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { action, autorun } from 'mobx';
import { UserAllPopupsTypes } from 'src/domains/layouts/state/popupState/PopupState';
import { CmsDrawerPagesState } from 'src/domains/players/state/CmsDrawerPagesState';
import { GeolocalizationState } from 'src/domains/layouts/state/geolocalizationState/GeolocalizationState';
import { createAccountPrimary } from 'src_server/trpc/types';
import { ExternalApi } from 'src/domains/layouts/state/externalApi/ExternalApi';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from './BasicDataModel';
import { ModalState } from 'src/domains/layouts/webview/components/modals/Modal.state';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { BannersBoxState } from 'src/domains/layouts/state/bannersBoxState/BannersBoxState';
import { AccountDrawerNew } from 'src/domains/layouts/state/AccountDrawerNew.state';
import { BlockingNotificationsState } from './BlockingNotification.state';

const AccountStatusEnum = createAccountPrimary.AccountStatusEnum;

interface PlayersCallbacksType {
    isShowQuickBet: () => boolean;
    largeDesktopIsBiggerOrEq: () => boolean | null;
    whenUserLogin: () => void;
    streamViewDetails: () => StreamViewDetails;
    getIsBrowser: () => boolean;
    shopPopup: (popup: UserAllPopupsTypes) => void;
}

export class AppPlayersState {
    public readonly withdrawalsListState: WithdrawalsListState;
    public readonly marketingQuestionsState: MarketingQuestionsState;
    public readonly marketingNotificationsState: MarketingNotificationsState;
    public readonly customKeyboard: CustomKeyboardState;
    public readonly confirmationBox: ConfirmationBoxState;
    public readonly messageBox: MessageBoxState;
    public readonly signupPopupState: SignupPopupState;
    public readonly accountState: AccountState;
    public readonly loginState: StarLoginState;
    public readonly signUpState: SignupType;
    public readonly specialEvents: SpecialEvents;
    public readonly usersState: UsersState;
    public readonly luckyKingWalletState: LuckyKingWalletState;
    public readonly cmsDrawerPagesState: CmsDrawerPagesState;
    public readonly streamViewDetails: () => StreamViewDetails;
    public readonly accountDrawerNew: AccountDrawerNew;
    public readonly modalState: ModalState;
    public readonly blockingNotificationsState: BlockingNotificationsState;

    public constructor(
        private readonly common: Common,
        googleTagManager: GoogleTagManagerState,
        starRouter: StarRouter,
        config: ConfigComponents,
        language: LanguagesState,
        callbacks: PlayersCallbacksType,
        env: EnvironmentState,
        geolocalization: GeolocalizationState,
        bannersBoxState: BannersBoxState
    ) {
        this.usersState = new UsersState(common, config.config, {
            canDownloadTerms: config.config.termsAndConditionsDownloadLink,
        });

        this.withdrawalsListState = new WithdrawalsListState(common.trpcClient);
        this.accountState = new AccountState(common, this.usersState, this.withdrawalsListState, config.precision);

        this.marketingQuestionsState = new MarketingQuestionsState(common.trpcClient, this.usersState);
        this.marketingNotificationsState = new MarketingNotificationsState(this.marketingQuestionsState, this.common);

        this.modalState = new ModalState(starRouter);

        this.customKeyboard = new CustomKeyboardState();
        this.confirmationBox = new ConfirmationBoxState();
        this.messageBox = new MessageBoxState(starRouter);
        this.signupPopupState = new SignupPopupState();

        this.loginState = new StarLoginState(
            common,
            starRouter,
            { whenUserLogin: callbacks.whenUserLogin },
            this.modalState,
            this.accountState,
            config
        );

        this.accountDrawerNew = new AccountDrawerNew(
            bannersBoxState,
            config,
            this.modalState,
            starRouter,
            env.emotionTheme
        );

        this.signUpState = {
            type: 'SignupPrimary',
            state: new SignUpState({
                customKeyboard: this.customKeyboard,
                accountState: this.accountState,
                language: language,
                config: config,
                signupPopupState: this.signupPopupState,
                router: starRouter,
                googleTagManager: googleTagManager,
                env,
                geolocalization,
                common,
                modalState: this.modalState,
            }),
        };

        this.specialEvents = new SpecialEvents(
            starRouter,
            this.signUpState,
            this.loginState,
            this.accountState,
            googleTagManager,
            common,
            {
                largeDesktopIsBiggerOrEq: callbacks.largeDesktopIsBiggerOrEq,
            }
        );

        this.luckyKingWalletState = new LuckyKingWalletState(config, this.usersState, starRouter);

        this.streamViewDetails = callbacks.streamViewDetails;

        this.cmsDrawerPagesState = new CmsDrawerPagesState(this.usersState, this.customKeyboard);

        this.blockingNotificationsState = new BlockingNotificationsState(
            this.common.trpcClient,
            BasicDataModel.get(this.common)
        );

        starRouter.onChangeAccountView((_prevAccountView, nextAccountView) => {
            if (nextAccountView?.account === 'top-up' || nextAccountView?.account === 'limits') {
                if (config.config.rollingNetDepositLimit === true) {
                    this.usersState.depositLimitsData.refresh();
                } else {
                    this.usersState.depositLimitsDataOld.refresh();
                }
            }
        });

        if (callbacks.getIsBrowser()) {
            this.runOnStart();
        }
        this.detectBasicData(config.config.detectLoginInMobileApp);
    }

    private runOnStart = (): void => {
        autorun(async () => {
            const accountStatus = this.accountState.account?.basicDataReady?.status;

            if (accountStatus === AccountStatusEnum.SUSPENDED) {
                await this.accountState.handleLogout();
            }
        });

        let prevUser: number | null = null;
        autorun(() => {
            const userId = this.common.session.userId;

            if (prevUser !== null && userId === null) {
                this.messageBox.runActionNotLogged();
            }

            prevUser = userId;
        });
    };

    private detectBasicData = (detectLoginInMobileApp: boolean): void => {
        if (detectLoginInMobileApp === false) {
            return;
        }

        autorun((dispose) => {
            const basicDataComp = BasicDataModel.get(this.common).basicDataReady;
            const id = basicDataComp?.id ?? null;
            const pushNotificationsEnabled = basicDataComp?.pushNotificationsEnabled;
            const isDataValid = id !== null;
            if (isDataValid) {
                this.sendInfoAboutFirstLoginToMobileApp(id, pushNotificationsEnabled);
                dispose.dispose();
            }
        });
    };

    @action private sendInfoAboutFirstLoginToMobileApp = (
        id: number,
        pushNotificationsEnabled: boolean | null | undefined
    ): void => {
        if (pushNotificationsEnabled === null || pushNotificationsEnabled === undefined) {
            return;
        }
        ExternalApi.get(this.common).sendFirstLoginMsg(id, pushNotificationsEnabled);
    };
}

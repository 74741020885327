import { observer } from 'src/utils/mobx-react';
import React from 'react';
import { YaspaIFrameStyled } from './Yaspa.style';
import { z } from 'zod';

interface YaspaIframePropsType {
    src: string;
}

const iframeMessageZod = z.object({
    type: z.union([z.literal('success'), z.literal('failure'), z.literal('redirect')]),
    redirectUrl: z.string(),
});

const handleOnMessage = (message: string): void => {
    const validate = iframeMessageZod.safeParse(JSON.parse(message));
    if (validate.success === false) {
        return;
    }

    if (validate.data.type === 'redirect') {
        window.open(validate.data.redirectUrl, '_blank');
    }
};

export const YaspaIframe = observer('YaspaDeposit', ({ src }: YaspaIframePropsType): JSX.Element => {
    return (
        <YaspaIFrameStyled
            src={src}
            onMessage={handleOnMessage}
        />
    );
});

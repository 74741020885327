import { observer } from 'src/utils/mobx-react';
import { DepositYaspaPageType, YaspaState } from './Yaspa.state';
import React, { useState } from 'react';
import { YaspaContainer, YaspaDepositButton } from './Yaspa.style';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { useCommon } from 'src/domains/common/Common';

interface YaspaDepositPropsType {
    depositYaspaPage: DepositYaspaPageType;
}

export const YaspaDeposit = observer('YaspaDeposit', ({ depositYaspaPage }: YaspaDepositPropsType): JSX.Element => {
    const common = useCommon();

    const [state] = useState(() => new YaspaState(common, depositYaspaPage));

    return (
        <YaspaContainer>
            <YaspaDepositButton
                size='large'
                disabled={state.isButtonDisabled}
                onClick={state.submitDepositForm}
            >
                Instant Bank Payment
            </YaspaDepositButton>

            {state.error === null ? null : (
                <Messages
                    type='error'
                    marginTop='8px'
                    key={state.error}
                    message={state.error}
                />
            )}
        </YaspaContainer>
    );
});
